<!--在线评选-->
<template>
  <div class="showDiv">
    <!--面包屑-->
    <el-row class="breadTop">
      <el-col>
        <top-path-list :path-obj="pathObj"></top-path-list>
      </el-col>
    </el-row>
    <!--内容部分-->
    <div class="news-div listDivMinHeight">
      <div class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="toDetail(item)">
        <div class="notice-item-img"><img :src="item.actiImage" alt/></div>
        <div class="rightContent">
          <div class="notice-item-title" :title="item.actiName">{{ item.actiName }}</div>
          <div class="notice-item-content">
            <text-ellipsis :text="item.actiOverview" :height="50" style="width: 100%">
              <template slot="more">...</template>
            </text-ellipsis>
          </div>
          <div class="notice-item-date flexAlignItemCenter">
            <img src="@/assets/img/giEdifice/grayTimer.svg" alt="" class="imgClass">
            {{ item.createTime }}
          </div>
        </div>
      </div>
    </div>
    <!--分页-->
    <div class="page">
      <el-pagination
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :current-page.sync="page"
        @current-change="pageChange"
        @size-change="handleSizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableTotal">
      </el-pagination>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import axios from 'axios'
import textEllipsis from '@/components/textellipsis'
export default {
  name: "index",
  components: { topPathList, textEllipsis },
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标品牌',
        },
        {
          name: '在线评选',
          path: '/giBrand/onlineSelection/index'
        },
      ],
      noticeList: [],
      page: 1,
      // 总条数
      tableTotal: 0,
      pageSize: 10,
    }
  },
  methods: {
    // 条数变化
    handleSizeChange(val) {
      this.pageSize = val
      this.getList();
    },
    pageChange(val) {
      this.page = val
      this.getList();
    },
    // 上一页
    prevClick(val) {
      this.page = val
      this.getList()
    },
    // 下一页
    nextClick(val) {
      this.page = val
      this.getList()
    },
    getList() {
      let params = {
        current: this.page,
        size: this.pageSize,
      }
      axios({
        method: "post",
        url: process.env.VUE_APP_HUSSAR_DEFAULT_API + '/api/portal/onlineselection/onlineselectionList',
        data: params
      }).then(res => {
        if (res.data.code == 200 || res.data.code == 10000) {
          this.tableTotal = res.data.count || 0;
          this.noticeList = res.data.data
          /*for (let i = 0; i < this.noticeList.length; i++) {
            this.noticeList[i].url = process.env.VUE_APP_HUSSAR_DEFAULT_API + "/attachment/showPicture?image=" + `${this.noticeList[i].actiImage}`
          }*/
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
    // 跳转详情
    toDetail(news) {
      this.$router.push({
        path: '/giBrand/onlineSelection/onlineSelectionDetail',
        query: {
          actiId: news.actiId,
          actiName: news.actiName,
        }
      });
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .showDiv {
    text-align: center;
    background-color: #f8f9fa !important;
  }
  .breadTop {
    width: 1200px;
    margin: auto;
  }
  .news-div {
    min-height: 487px;
    .notice-item {
      width: 1160px;
      margin: 0px auto 29px;
      padding: 13px 16px;
      background: #FFFFFF;
      border-radius: 5px;
      height: 161px;
      .notice-item-img {
        float: left;
        img {
          width: 270px;
          height: 160px;
        }
      }
      .rightContent {
        text-align: left;
        margin-left: 311px;
        margin-top: 17px;
        .notice-item-title {
          font-size: 18px;
          font-family: SourceHanSansCN-Bold, SourceHanSansCN;
          font-weight: bold;
          color: #333333;
          line-height: 27px;
          overflow: hidden;//超出隐藏
          text-overflow: ellipsis;//显示省略号
          white-space: nowrap;//禁止文本自动换行
        }
        .notice-item-content {
          margin-top: 12px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
          line-height: 23px;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        .notice-item-date {
          margin-top: 18px;
          text-align: left;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #ABB2BF;
          line-height: 21px;
          .imgClass {
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }
    }
    .notice-item:hover {
      cursor: pointer;
    }
  }
  .page {
    margin-bottom: 20px;
  }

  @media screen and (max-width: 1366px) {
    .showDiv {
      text-align: center;
      background-color: #f8f9fa !important;
    }
    .breadTop {
      width: 1200px;
      margin: auto;
    }
    .news-div {
      .notice-item {
        width: 1160px;
        margin: 0px auto 29px;
        padding: 13px 16px;
        background: #FFFFFF;
        border-radius: 5px;
        height: 161px;
        .notice-item-img {
          float: left;
          img {
            width: 270px;
            height: 160px;
          }
        }
        .rightContent {
          text-align: left;
          margin-left: 311px;
          margin-top: 17px;
          .notice-item-title {
            font-size: 18px;
            font-family: SourceHanSansCN-Bold, SourceHanSansCN;
            font-weight: bold;
            color: #333333;
            line-height: 27px;
            overflow: hidden;//超出隐藏
            text-overflow: ellipsis;//显示省略号
            white-space: nowrap;//禁止文本自动换行
          }
          .notice-item-content {
            margin-top: 12px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #666666;
            line-height: 23px;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
          .notice-item-date {
            margin-top: 18px;
            text-align: left;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #ABB2BF;
            line-height: 21px;
            .imgClass {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }
          }
        }
      }
      .notice-item:hover {
        cursor: pointer;
      }
    }
    .page {
      margin-bottom: 20px;
    }
  }
</style>
